import request from '@/utils/request'

export function getPage (page, noitems) {
  return request({
    url: '/surveys/response?page=' + page + '&item=' + noitems,
    method: 'post'
  })
}
export function search (data) {
  return request({
    url: '/surveys/response',
    method: 'post',
    data
  })
}
export function destroy (_id) {
  return request({
    url: '/surveys/response/' + _id,
    method: 'delete'
  })
}
export function exportExcel (data) {
  return request({
    url: '/surveys/response/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
