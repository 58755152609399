<template>
  <div class="app-container">
    <el-row class="margin-bottom-20" type="flex" justify="begin">
      <el-col :lg="12" :md="12" :sm="16" :xs="16" :offset="0">
      <el-input prefix-icon="el-icon-search" width="500" size="mini" placeholder="Buscar" v-model="searchForm.query" @change="handleSearch" clearable></el-input>
      </el-col>
      <el-button
        @click.stop.prevent="handleSearch"
        class="margin-left-20"
        type="primary"
        size="mini"
        icon="el-icon-search"
      >Buscar</el-button>
      <el-button
        class="margin-left-20"
        @click="handleClean"
        type="warning"
        size="mini"
        icon="el-icon-delete"
      >Limpiar Filtros</el-button>
      <el-button
        class="margin-left-20"
        @click.stop.prevent="exportExcelFile"
        type="success"
        icon="el-icon-bottom"
        size="mini"
      >Exportar Excel</el-button>
    </el-row>
    <el-row class="margin-bottom-20" type="flex" justify="begin">
      <el-col :span="5" :offset="0">
          <span>Encuestas</span> <br>
          <template>
            <el-select
              size="mini"
              v-model="searchForm.title"
              filterable
              placeholder="Ninguno"
              v-on:change="handleSearch()"
              clearable>
             <el-option
              v-for="itemT in listItemsSurveysTitle"
              :key="itemT.value"
              :label="itemT.title"
              :value="itemT.title">
              </el-option>
            </el-select>
           </template>
      </el-col>
      <el-col :span="5">
        <div class=" margin-left-10">
          <span class=" margin-filter">Fecha inicial</span> <br>
          <el-date-picker
          class=" margin-left-10"
            @change="handleSearch()"
            clearable
            size="mini"
            v-model="searchForm.initDate"
            type="date"
            format="dd-MM-yyyy"
            value-format="yyyy-MM-dd"
            placeholder="Mayor a ">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="5" class=" margin-left-10">
        <div class=" margin-left-10">
          <span class=" margin-filter">Fecha final</span> <br>
          <el-date-picker
          class=" margin-left-10"
            @change="handleSearch()"
            clearable
            size="mini"
            v-model="searchForm.endDate"
            type="date"
            format="dd-MM-yyyy"
            value-format="yyyy-MM-dd"
            placeholder="Menor a">
          </el-date-picker>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="end">
        <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
    </el-row>
    <el-table
      size="mini"
      v-loading="loading"
      :data="list"
      element-loading-text="Loading"
      fit
      highlight-current-row
      style="width: 100%"
    >
      <!-- <el-table-column type="selection" width="40"> </el-table-column> -->
       <el-table-column type="expand">
        <template slot-scope="props">
          <div v-html="props.row.answers"></div>
        </template>
      </el-table-column>
      <el-table-column label="ID" align="center">
        <template slot-scope="scope" >
          <el-tooltip class="item" effect="dark" placement="top-start">
            <div slot="content">{{scope.row._id}}</div>
            <span>{{ scope.row._id.substr(-4) }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Título" align="center">
        <template slot-scope="scope" >
          <span>{{ scope.row.title}}</span>
        </template>
      </el-table-column>
      <el-table-column label="No. Usuario" align="center">
        <template slot-scope="scope" >
          <span>{{ scope.row.idEmployed}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Nombre" align="center">
        <template slot-scope="scope" >
          <span>{{ scope.row.nameEmployed}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Completado" align="center">
        <div slot-scope="scope" v-if="scope.row.isCompleted === true">
            Completado
        </div>
        <div v-else>
             No completado
        </div>
      </el-table-column>
      <el-table-column label="Fecha Alta" align="center" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.updated_at | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Opciones" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            round
            :loading="loading"
            @click.native.prevent="handleDelete(scope.$index, scope.row)"
            >Eliminar</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
        <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
    </el-row>
  </div>
</template>
<script>
import { search, getPage, destroy, exportExcel } from '@/api/participation'
import { getSurveysTitle } from '@/api/listItems'

export default {
  data () {
    return {
      searchForm: {
        title: '',
        query: '',
        initDate: '',
        endDate: ''
      },
      totalPages: '',
      totalItemPage: '',
      currentPage: '',
      list: null,
      listItemsSurveysTitle: [],
      loading: false,
      loadingDelete: false,
      publishOptions: [{
        value: 'true',
        label: 'Publicado'
      }, {
        value: 'false',
        label: 'No publicado'
      }]
    }
  },
  mounted () {
    this.handleSearch()
    this.listSurveysTitle()
  },
  methods: {
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.showItempageValue)
        .then(response => {
          this.list = response.data.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      await getPage(this.currentPage, val)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearch () {
      this.loading = true
      await search(this.searchForm).then(response => {
        this.list = response.data.data
        console.log(this.list)
        this.totalPages = response.data.total
      }).catch(this.responseCatch).finally(() => (this.loading = false))
    },
    async listSurveysTitle () {
      this.loading = true
      await getSurveysTitle()
        .then(response => {
          console.log(response)
          this.listItemsSurveysTitle = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleDelete (index, row) {
      this.loadingDelete = true
      this.$confirm('Se borrará permanentemente. Continuar?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          await destroy(row._id)
            .then(response => {
              // let res = response.data
              this.$message({
                showClose: true,
                message: 'Se eliminó correctamente!',
                type: 'success'
              })

              this.list.splice(index, 1)
            })
            .catch(this.responseCatch)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled'
          })
        })
        .finally(() => (this.loadingDelete = false))
    },
    async exportExcelFile () {
      this.loading = true
      await exportExcel(this.searchForm)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          const name = 'Reporte_de_respuestas_de_encuestas_' + new Date() + '.xlsx'
          link.setAttribute('download', name)
          document.body.appendChild(link)
          link.click()
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleClean () {
      this.searchForm.query = ''
      this.searchForm.title = ''
      this.searchForm.initDate = ''
      this.searchForm.endDate = ''
      this.handleSearch()
    }
  }
}
</script>
